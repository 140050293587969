import React, { Component } from 'react'
import PreviewHeader from './PreviewHeader'

import './Labels.css';

// splits adds into groups of 30 (30 labels per page)
function paginate(adds) {
  const PER_PAGE = 30;
  const pagedLabels = [];
  for (let i = 0; i < adds.length; i+=PER_PAGE) {
    pagedLabels.push(adds.slice(i, i+PER_PAGE));
  }
  return pagedLabels;
}

class LabelsPreview extends Component {

  render() {
    const adds = this.props.data || [];
    const labelPages = paginate(adds);

    return (
      <div className="labels-preview">
        <PreviewHeader></PreviewHeader>
        <hr></hr>
        <div className="labels">
          {labelPages.length === 0 && (
            <div>Upload a text file in "Import Albums" and click "Send to Print" to preview new music labels.</div>
          )}
          {labelPages.length > 0 && labelPages.map((page, pageIdx) => (
            <div className="labels-page" key={pageIdx}>
              {page.length > 0 && page.map((add, addIdx) => {
                let hasMedia = add.media !== "";
                let hasGenre = add.genre !== "";
                let gridAreaStyle = (hasMedia)
                  ? { "gridTemplateAreas": '"artist artist media" "album album album" "genre . date"' }
                  : { "gridTemplateAreas": '"artist artist artist" "album album album" "genre . date"' }
                return (
                  <div className="label" key={addIdx} style={ gridAreaStyle }>
                    <span className="label-artist">{add.artist}</span>
                    {hasMedia !== "" && <span className="label-media">{add.media}</span>}
                    <span className="label-album">{add.album}</span>
                    {hasGenre !== "" && <span className="label-genre">{add.genre}</span>}
                    <span className="label-date">{add.date}</span>
                  </div>
                );
              })}
              <div className="page-break"></div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default LabelsPreview;
