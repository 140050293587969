import React, { Component } from 'react';
import InlineEdit from 'react-edit-inline2';
import moment from 'moment';
import { Button, Input, Label } from 'reactstrap';
import { readFile, parseTextFromReader, nestedArrayMaxLength } from '../utils';

const DEFAULT_COL = {
  4: ['artist', 'album', 'media', 'label'],
  5: ['artist', 'album', 'date', 'media', 'label'],
  6: ['artist', 'album', 'date', 'media', 'label', 'genre']
};

const ColumnSelect = (props) => (
  <Input
    type="select"
    name={"col"+props.idx}
    value={props.value}
    onChange={props.onChange}
  >
    <option value='artist'>Artist</option>
    <option value='album'>Album</option>
    <option value='date'>Date</option>
    <option value='media'>Media Type</option>
    <option value='label'>Label</option>
    <option value='genre'>Genre</option>
  </Input>
);

const convertToAddsObj = (columns, nestedArray, overrideAddDate, newAddDate) => {
  let newAdds = [];
  for (let i = 0; i < nestedArray.length; i++) {
    let addObj = {};
    for (let j = 0; j < nestedArray[i].length; j++) {
      let val = nestedArray[i][j];
      if (val === "-") val = "";
      if (columns[j] === 'date' && overrideAddDate) val = moment(newAddDate, "YYYY-MM-DD").format("MM/DD/YYYY");
      addObj[columns[j]] = val;
    }
    newAdds.push(addObj);
  }
  return newAdds;
};

class ImportPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      imports: [],
      numCols: 0,
      columns: [],
      dateAdds: moment().format('YYYY-MM-DD'),
      overrideAddDate: false,
      importButtonEnabled: false,
      sentToPrint: false,
      addsObjArr: []
    };

    this.callbackNewAdds = this.props.onNewAdds;

    this.onFileInputChanged = this.onFileInputChanged.bind(this);
    this.onSelectInputChanged = this.onSelectInputChanged.bind(this);
    this.onInlineInputChanged = this.onInlineInputChanged.bind(this);
    this.onCheckInputChanged = this.onCheckInputChanged.bind(this);
    this.onClickOnlyPrint = this.onClickOnlyPrint.bind(this);
    this.onClickSubmitNewAdds = this.onClickSubmitNewAdds.bind(this);
  }

  onFileInputChanged(evt) {
    let self = this;
    if (evt.target.files.length > 0) {
      const file = evt.target.files[0];
      readFile(file, function(event) {
        let parsedText = parseTextFromReader(event.target.result);
        let numCols = nestedArrayMaxLength(parsedText);
        self.setState({
          imports: parsedText,
          numCols,
          columns: DEFAULT_COL[numCols] || [],
          importButtonEnabled: true,
          overrideAddDate: false
        });
      });
    }
  }

  onSelectInputChanged(evt, idx) {
    const newValue = evt.target.value;
    this.setState((prevState) => {
      let columns = prevState.columns;
      columns[idx] = newValue; 
      return { columns };
    });
  }

  onCheckInputChanged(evt) {
    const newValue = evt.target.value;
    this.setState({ overrideAddDate: newValue });
  }

  onInlineInputChanged(obj, row, col) {
    this.setState((prevState) => {
      let imports = prevState.imports;
      imports[row][col] = obj.value;

      // update print preview if already sent to print preview
      if (this.state.sentToPrint) {
        // TO-DO: utilize addsObjArr in order to avoid recreating addsObjs each time
        this.callbackNewAdds(convertToAddsObj(
          this.state.columns, this.state.imports, this.state.overrideAddDate, this.state.dateAdds));
      }

      return { imports };
    });
  }

  // send to parent component only (for printing)
  onClickOnlyPrint(evt) {
    this.callbackNewAdds(convertToAddsObj(
      this.state.columns, this.state.imports, this.state.overrideAddDate, this.state.dateAdds));
    this.setState({ sentToPrint: true });
  }

  // send to parent component AND submit new adds to backend
  onClickSubmitNewAdds(evt) {
    this.callbackNewAdds(convertToAddsObj(
      this.state.columns, this.state.imports, this.state.overrideAddDate, this.state.dateAdds));
    this.setState({ sentToPrint: true });

    // submit to backend
    //TO-DO
  }

  render() {
    const { imports, numCols, columns } = this.state;
    const tableColumns = ([...Array(numCols)].map((e, i) => (
      <th key={i}>
        <ColumnSelect idx={i} value={columns[i]} onChange={(e) => this.onSelectInputChanged(e, i)}></ColumnSelect>
      </th>
    )));

    return (
      <div>
        <div className="tab-content-header">
          <h3>Import Albums</h3>
          <div className="inline">
            <Input
              className="input-file a-inline" 
              type="file"
              accept=".txt,.csv,.tsv.,text/plain,text/csv"
              onChange={this.onFileInputChanged}
            ></Input>
          </div>
          <div className="mt-3">
            <span>Add Date</span>
            <Input
              className="input-date a-inline" 
              type="date" 
              value={this.state.dateAdds}
              onChange={event => this.setState({ dateAdds: event.target.value })}
              required={true}
            ></Input>
            <Label check>
              <Input
                className="input-checkbox a-inline" 
                type="checkbox"
                value={this.state.overrideAddDate}
                onChange={this.onCheckInputChanged}
              ></Input>
              Override Add Date
            </Label>
            <Button
              className="a-inline"
              color="secondary"
              disabled={!this.state.importButtonEnabled}
              onClick={this.onClickOnlyPrint}
            >
              Send to Print
            </Button>
{/*            <Button
              className="a-inline"
              color="primary"
              disabled={!this.state.importButtonEnabled}
              onClick={this.onClickSubmitNewAdds}
            >
              Submit New Adds
            </Button>*/}
          </div>
        </div>
        <hr></hr>
        <table className="import-adds">
          <thead>
            <tr>
              {tableColumns}
            </tr>
          </thead>
          <tbody>
            {imports.length > 0 && imports.map((release, i) => (
              <tr key={i}>
                {release.length > 0 && release.map((cell, j) => (
                  <td key={j}>
                    <InlineEdit
                      paramName="value"
                      text={cell}
                      change={(obj) => this.onInlineInputChanged(obj, i, j)}
                      activeClassName="editing"
                    >
                    </InlineEdit>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ImportPage
