import React, { Component } from 'react'
import PreviewHeader from './PreviewHeader'

import './Adds.css';

const AddsTableHead = (props) => (
  <thead>
    <tr>
      <th className="adds-table-date">Date</th>
      <th className="adds-table-artist">Artist</th>
      <th className="adds-table-album">Album</th>
      <th className="adds-table-media">Media</th>
      <th className="adds-table-label">Label</th>
    </tr>
  </thead>
);

const AddsTableRow = (props) => (
  <tr>
    <td className="adds-table-date">{props.add.date}</td>
    <td className="adds-table-artist">{props.add.artist}</td>
    <td className="adds-table-album">{props.add.album}</td>
    <td className="adds-table-media">{props.add.media}</td>
    <td className="adds-table-label"><span>{props.add.label}</span></td>
  </tr>
);

class AddsPreview extends Component {

  render() {
    const adds = this.props.data || [];

    return (
      <div className="adds-preview">
        <PreviewHeader></PreviewHeader>
        <hr></hr>
        <div className="adds">
          {adds.length === 0 && (
            <div>Upload a text file in "Import Albums" and click "Send to Print" to preview new music adds list.</div>
          )}
          {adds.length > 0 && (
            <div>
              <div className="page">
                <div className="page-header page-one">
                  <div className="page-title">New Releases....</div>
                </div>
                <table className="adds-table">
                  <AddsTableHead></AddsTableHead>
                  <tbody>
                    {adds.map((add, idx) => <AddsTableRow add={add} key={idx}></AddsTableRow>)}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AddsPreview;
