
const parseDelimitedFile = (text, delimiter) => {
    const textAsLines = text.split('\r');
    const splitLines = textAsLines.map(line => line.split(delimiter).map(v => {
        if (v.length > 0) return v.trim();
        else return "-";
    }));
    return splitLines;
}

export const readFile = (file, onloadCallback) => {
    const reader = new FileReader();
    reader.onload = onloadCallback;
    reader.onerror = (e) => {
        reader.abort();
    };
    reader.readAsText(file);
}

export const parseTextFromReader = (text) => {
    const lines = (text.indexOf('\r') >= 0 || text.indexOf('\n') >= 0) ? text.match(/\r|\n/g).length : 0;
    const tabs = text.indexOf('\t') >= 0 ? text.match(/\t/g).length : 0;
    const commas = text.indexOf(',') >= 0 ? text.match(/,/g).length : 0;

    if (tabs > lines) {
    
        // if there are more tabs than there are lines, try parsing as TSV
        return parseDelimitedFile(text, '\t');
    
    } else if (commas > (tabs + lines)) {
    
        // if there are more commas than there are lines and tabs, try parsing as CSV
        return parseDelimitedFile(text, ',');

    } else {

        console.log('Invalid file format. Please submit as a tab-separated or comma-separated file. ')
        return [];

    }
    
}

export const nestedArrayMaxLength = (nestedArray) => {
    return Math.max.apply(Math, nestedArray.map(function (el) { return el.length }));
}
