import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint } from '@fortawesome/pro-regular-svg-icons'

const PreviewHeader = (props) => (
  <div className="tab-content-header">
    <h3>Preview</h3>
    <div className="inline">
      <Button
        color="secondary"
        className="a-inline"
        onClick={(evt) => { window.print(); }}>
          <FontAwesomeIcon icon={faPrint}></FontAwesomeIcon> 
          Print
      </Button>
    </div>
  </div>
);

export default PreviewHeader;