import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col, Input } from 'reactstrap';
import classnames from 'classnames';

import AddsPreview from './components/AddsPreview';
import LabelsPreview from './components/LabelsPreview';
import ImportPage from './components/ImportPage';
// import CatalogPage from './components/CatalogPage';

import './styles/fonts.css';
import './styles/screen.css';
import './styles/print.css';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
      catalog: [],
      importAdds: []
    };

    this.toggleTab = this.toggleTab.bind(this);
    this.onNewAdds = this.onNewAdds.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  onNewAdds(adds) {
    this.setState({ importAdds: adds });
  }

  render() {
    return (
      <div>
        <header>
          <h2>WKDU New Music</h2>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggleTab('1'); }}
              >
                Import Albums
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggleTab('2'); }}
              >
                Print New Releases
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggleTab('3'); }}
              >
                Print New Labels
              </NavLink>
            </NavItem>
{/*            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '4' })}
                onClick={() => { this.toggleTab('4'); }}
              >
                Catalog
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '5' })}
                onClick={() => { this.toggleTab('5'); }}
              >
                Help
              </NavLink>
            </NavItem>*/}
          </Nav>
        </header>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" id="tab-import">
            <Row>
              <Col sm="12">
                <ImportPage onNewAdds={this.onNewAdds}></ImportPage>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" id="tab-adds">
            <Row>
              <Col sm="12">
                <AddsPreview data={this.state.importAdds}></AddsPreview>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3" id="tab-labels">
            <Row>
              <Col sm="12">
                <LabelsPreview data={this.state.importAdds}></LabelsPreview>
              </Col>
            </Row>
          </TabPane>
          {/*<TabPane tabId="4">
            <Row>
              <Col sm="12">
                <CatalogPage data={this.state.catalog}></CatalogPage>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                
              </Col>
            </Row>
          </TabPane>*/}
        </TabContent>
      </div>
    );
  }
}
